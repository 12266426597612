import React from "react";
import Card from "react-bootstrap/Card";
import { ImArrowRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>

          I am a Data Scientist with two years of work experience.
            <br />
            <br />
            I'm from Chennai, India.
            <br />
            <br />
            I graduated with a B.E. in Computer Science and Engineering at SSN College of Engineering.
            <br />
            <br />
            Apart from connecting the dots in data, I love to,
          </p>
          <ul>
          <li className="about-activity">
              <ImArrowRight /> I love to discuss unconventional topics that are too serious for my age.
 
            </li>
            <li className="about-activity">
              <ImArrowRight />  I sing songs, Play Guitar, and occasionally write my lyrics🎶
            </li>
            <li className="about-activity">
              <ImArrowRight /> Shameless Amateur in Standup Comedy (I love performing comedy to silence the audience 👀)
            </li>
            <li className="about-activity">
              <ImArrowRight />  Expressing my candid viewpoints in multi-modal forms, Hit me up if you want to explore profound philosophies with me
            </li>
            <li className="about-activity">
              <ImArrowRight />  Contemporary Dancing (New-found ❤️ for fluid body movements)
            </li>
            <li className="about-activity">
              <ImArrowRight /> Me encanta aprender español, and shock others with my Hindi Grammar 👀
            </li>
          </ul>

          
          {/* <footer className="blockquote-footer">Varshini</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
