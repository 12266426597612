import React from 'react';
import Card from 'react-bootstrap/Card';
// import './Untamed.css';
import Type from './Type';

function Untamed() {
    return (
      <div>
        {/* style={{ margin: 0, padding: 0 }} */}
        <Card className="quote-card-view">
          <Card.Body>
            <blockquote className="blockquote mb-0">
              <div className="quote-card-view">
                <div> 
                    {/* style={{ padding: '20px', textAlign: 'left' }} */}
                    <Type/>
                    <br/>
                    <p>
                    Empowering educators with data is more than a mission; it's a commitment to reshape the learning landscape.
                    </p>

                    <p>
                    This is an initiative to empower students, fostering <br />
                    <strong className="purple">responsibility, curiosity, and fulfillment</strong>. <br />
                    <br />
                    Let's redefine the Learning Experience!
                    </p>

                    <p>
                    Join us in streamlining the learning process, rekindling our untamed essence. In our research, we tackle open-ended questions in Education.
                    </p>

                    <p>
                    <em>Some Problems we are working on:</em>
                    </p>

                    <ol>
                    <li>Personalized Tutors for middle school kids, for English, Math, Social Science</li>
                    <li>Adaptive tests for comprehensive evaluation of a student's strengths & weaknesses</li>
                    <li>Engaging tools to facilitate learning in endangered Indian Languages</li>
                    <li>Mental Health Bot - to cope with stress during the Boards and Competitive exams</li>
                    </ol>
                </div>
              </div>
  
              <div className="contact-info">
                <p >Contact Information:</p>
                <p style={{ color: 'yellow' }}>Email: varshini.balaji@creativethee.com</p>
              </div>
            </blockquote>
          </Card.Body>
        </Card>
      </div>
    );
  }
  
  export default Untamed;