import Particle from "../Particle";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Untamed from "./Untamed";
import plearning from "../../Assets/Allows-Learners-to-Take-Control.png";


function Initiatives() {
  return (
    <Container fluid className="about-section">
      <Row style={{ justifyContent: "center", padding: "100px" }}>
        <Col md={5} className="about-img">
          <img src={plearning} alt="about" className="img-fluid" />
        </Col>
        <Col md={7} className="quote-card-view" style={{ paddingTop: "0px", paddingBottom: "50px" }}>
          <h1>
            What is Untamed? <strong className="purple"></strong>
          </h1>
          <Untamed />
        </Col>
      </Row>
    </Container>
  );
}

export default Initiatives;
