import Particle from "../Particle";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function List100() {
  return (
    <Container fluid className="about-section">
      <Row style={{ justifyContent: "center", padding: "100px" }}>
        <Col md={7} className="quote-card-view" style={{ paddingTop: "0px", paddingBottom: "50px" }}>
    
    <div>
      <div className="post-content">
        <p>Things I want to do before I turn 30, still adding (🚫 not sorted by priority). </p>
        <p>Some Ambitious, some peppy and some downright WEIRD 😉</p>
        <p>Hit me up if you have any recommendations.<br /></p>
        <ol>
        <li>✗ Measurable impact 'Untamed' Outreach, partner with leading NGOs in India by 2026</li>
        <li>✗ Run a highly quality podcast on Data Science and Responsible AI (under the works)</li>
        <li>✗ Set up passive income creation through my software products</li>
        <li>✗ Present my research at NeurIPS (planning on this real soon)</li>
        <li>✗ Take my parents on a fully funded International trip (preferred Singapore)</li>
        <li>✗ Speak at TED</li>
        <li>✗ Publish seminal research with high citation (target 2024🤞)</li>
        <li>✗ Write and produce my own Single (Music)</li>
        <li>✗ Hit the high notes in Million Dreams using my chest voice </li>
        <li>✓ Perform Standup comedy in an open mic </li>
        <li>✓ Perform 'When I Look at You' live (P.S. my voice broke in many places)</li>
        <li>✗ Become a Kaggle Grandmaster</li>
        <li>✗ Win a Kaggle Contest</li>
        <li>✗ Scuba Diving 🤿</li>
        <li>✗ Sky Diving 🪂</li>
        <li>✗ Attend a Halloween Costume party (expected soon)</li>
        <li>✗ Visit a Goth club</li>
        <li>✗ Attend La Tomatina</li>
        <li>✗ Attend a Taylor Swift Concert (Eras tour is a dream, Maybe if she extends it till 2025🤞)</li>
        </ol>
      </div>

      <div id="disqus_thread">
        <iframe
          id="dsq-app7385"
          name="dsq-app7385"
          allowtransparency="true"
          frameBorder="0"
          scrolling="no"
          tabIndex="0"
          title="Disqus"
          width="100%"
          src="https://disqus.com/embed/comments/?base=default&amp;f=chiphuyen&amp;t_i=https%3A%2F%2Fhuyenchip.com%2Flist-100%2F&amp;t_u=https%3A%2F%2Fhuyenchip.com%2Flist-100%2F&amp;t_d=List%20100&amp;t_t=List%20100&amp;s_o=default#version=51dfeea10554db37f6ba310f2efb9cb8"
          style={{ width: '1px !important', minWidth: '100% !important', border: 'none !important', overflow: 'hidden !important', height: '7460px !important' }}
          horizontalScrolling="no"
          verticalScrolling="no"
        ></iframe>
      </div>

      <script>
        {/* Your Disqus script here */}
      </script>
    </div>
    
    </Col>
    </Row>
    </Container>
  );
}

export default List100;
