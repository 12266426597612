import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
  DiAtlassian,
  DiGoogleCloudPlatform,
  DiAndroid,
  DiDocker,
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiAws,
  DiSpark
} from "react-icons/di";
import {
  SiTensorflow,
  SiPytest,
  SiDvc,
  SiMlflow,
  SiStreamlit,
  SiDbt,
  SiFastapi,
  SiTableau,
  SiMicrosoftexcel,
  SiAdobepremierepro
} from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>

    
    
    
    <Col xs={4} md={2} className="tech-icons">
      <DiPython />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <DiMongodb />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <DiGit />
    </Col>
    
    {/* <Col xs={4} md={2} className="tech-icons">
      <DiNodejs />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <DiReact />
    </Col> */}
    
    <Col xs={4} md={2} className="tech-icons">
      <DiAtlassian />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <DiGoogleCloudPlatform />
    </Col>
    {/* <Col xs={4} md={2} className="tech-icons">
      <DiAndroid />
    </Col> */}
    <Col xs={4} md={2} className="tech-icons">
      <DiDocker />
    </Col>
  
    <Col xs={4} md={2} className="tech-icons">
      <DiAws />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <SiTensorflow />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <SiPytest />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <SiDvc />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <SiMlflow />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <SiStreamlit />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <SiDbt />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <SiFastapi />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <DiSpark />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <CgCPlusPlus/>
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <DiJava />
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <DiJavascript1 />
    </Col>
    
    </Row>
  );
}

export default Techstack;
