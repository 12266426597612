// Appoinment.js

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Appoinment() {
  const navigate = useNavigate();

  useEffect(() => {
    // Perform the redirection when the component mounts
    window.location.href = "https://topmate.io/varshini_balaji";
  }, []); // Empty dependency array ensures that this effect runs only once

  return null;
}

export default Appoinment;
