import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import speak_no_evil from "../../Assets/Projects/no_evil_monkey.png";
import chandler from "../../Assets/Projects/bing_dance.gif";
import monkey_brain from "../../Assets/Projects/monkey-mind-headspace.webp";
import research_paper from "../../Assets/Projects/arxiv.png";
import tape from "../../Assets/Projects/tape.png";
import puzzle from "../../Assets/Projects/puzzle.png";
import light_bulb from "../../Assets/light_bulb.gif";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works (To be Updated) </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={speak_no_evil}
              isBlog={false}
              title="spread-me-not"
              description="Real-time Credibility Scoring for Claims made in Tweets"
              ghLink="https://github.com/varshini2305/spread-me-not"
              demoLink=""
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={light_bulb}
              isBlog={false}
              title="Luminosity Classifier"
              description="Classify if the product in a product catalog pdf is a lighting object"
              ghLink="https://github.com/varshini2305/luminosity_classifier"
              demoLink="https://luminosity-classifier.streamlit.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={monkey_brain}
              isBlog={false}
              title="Micromanage Me"
              description="To force your monkey brains to focus on one thing, that's one page in the web at a time!!"
              ghLink="https://github.com/varshini2305/micromanage_me"
              demoLink=""
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chandler}
              isBlog={false}
              title="Could I Be More Chandler?"
              description="Chatbot that communicates with you like Friends Fame Chandler would?"
              ghLink="https://github.com/varshini2305/spread-me-not"
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tape}
              isBlog={false}
              title="Digital Tape"
              description="Interactive Web app to measure Area and other measures for your Plots and Homes"
              ghLink="https://github.com/varshini2305/digital-tape"
              demoLink=""              
            />
          </Col>

          

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={research_paper}
              isBlog={false}
              title="PaperGo"
              description="Research PDF Audio Generation to generate audio files for research PDFs, extending to a Research Assistant bot to simplify tedious Literature surveys"
              ghLink="https://github.com/varshini2305/varshini2305"
              demoLink="https://luminosity-classifier.streamlit.app/"
            />
          </Col>

          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={puzzle}
              isBlog={false}
              title="Machine Unlearning"
              description="Erase the influence of requested samples without hurting accuracy, Competition hosted by Google on Kaggle"
              ghLink=""
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
